import axios from 'axios';

// export const API_URL = 'http://13.51.238.43:3001/api/v2';
// export const ChatBaseUrl = 'http://13.51.238.43:3001';

export const ChatBaseUrl = 'https://pakprintwishes.com:3001';
export const API_URL = 'https://pakprintwishes.com:3001/api/v2';

// export const API_URL = 'https://lqgdc1b2-3001.uks1.devtunnels.ms/api/v2';
// export const ChatBaseUrl = 'https://lqgdc1b2-3001.uks1.devtunnels.ms';

// export const IMAGE_BASE_URL = 'https://pakprintwishes-main.s3.ap-south-1.amazonaws.com';
export const IMAGE_BASE_URL = 'https://pakprintwishes.com:3001';

// export const imageMissing = '/admin';
export const imageMissing = '';

export const callAPi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type':
      'multipart/form-data; boundary=AaB03x' +
      '--AaB03x' +
      'Content-Disposition: file' +
      'Content-Type: png' +
      'Content-Transfer-Encoding: binary' +
      '...data... ' +
      '--AaB03x--',
    Accept: 'application/json',
    type: 'formData',
    'X-Robots-Tag': 'noindex, nofollow',
  },
});

callAPi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
