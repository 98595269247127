import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useRef, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TablePagination,
  Grid,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// components
import Scrollbar from '../components/scrollbar';
// sections
import { getGalleryMethod } from '../redux/slice/gallerySlice';
import GalleyImageCard from '../components/card/GalleyImageCard';
import GalleryService from '../redux/api/GalleryService';
import Iconify from '../components/iconify/Iconify';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function GalleryImagesPage() {
  const { id } = useParams();

  const inputFile = useRef(null);

  const dispatch = useDispatch();

  const data = useSelector((state) => state.gallery);

  const [page, setPage] = useState(0);

  const [order] = useState('asc');

  const [orderBy] = useState('name');

  const [filterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(8);

  const [reFetch, setReFetch] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(data.data !== null ? data.data : [], getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const selectFiles = (event) => {
    const selectedFiles = event.target.files;
    const imagesFile = [];
    for (let i = 0; i < selectedFiles.length; i += 1) {
      imagesFile.push(URL.createObjectURL(selectedFiles[i]));
    }
    // setSelectImages(selectedFiles);
    handleImage(selectedFiles);
  };

  const handleImage = async (images) => {
    await GalleryService.add({ folderId: id, image: images })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getGalleryMethod(id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleDeleteImgae = async (id) => {
    try {
      const res = await GalleryService.deleteGallaryImage(id);
      if (res.data.success) {
        setReFetch(!reFetch);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getGalleryMethod(id));
  }, [id, reFetch,dispatch]);

  return (
    <>
      <Helmet>
        <title> Galley Images | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Gallery Images
          </Typography>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            multiple
            id="file"
            ref={inputFile}
            onChange={selectFiles}
            style={{ display: 'none' }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              inputFile.current.click();
            }}
            startIcon={<Iconify icon="uil:image-upload" />}
            sx={{ marginX: '10px' }}
          >
            Upload Images
          </Button>
        </Stack>
        <Card>
          <Scrollbar>
            <Grid container spacing={2} p={2}>
              {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={row?._id}>
                  <GalleyImageCard onDelete={handleDeleteImgae} row={row} />
                </Grid>
              ))}
            </Grid>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Try checking for typos or using complete words.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[8, 16, 24, 48, 96]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
